import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { facebook } from 'react-icons-kit/fa/facebook';
import { twitter } from 'react-icons-kit/fa/twitter';
import { github } from 'react-icons-kit/fa/github';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  CopyrightText,
  SocialList,
  SelectWrapper,
} from './footer.style';

import LogoImage from './small-image.png';

const Footer = ({ row, col, colOne, colTwo }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        menuWidgets {
          id
          title
          menu {
            id
            link
            text
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container width="1260px">
        <Box className="row copyright" {...row}>
          <CopyrightText>
            <Text
              className="text"
              content={`© ${ new Date().getYear() + 1900 } Godalming & Villages Residents. All Rights Reserved`}
            />
          </CopyrightText>
          <SocialList>
            <li className="twitter">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/godalmingres"
                aria-label="social share link"
              >
                <Icon icon={twitter} />
              </a>
            </li>
            <li className="facebook">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/godalmingresidents"
                aria-label="social share link"
              >
                <Icon icon={facebook} />
              </a>
            </li>
          </SocialList>
        </Box>
        <Box className="row copyright" {...row}>
          <CopyrightText style={{ marginTop: '0.25em' }}>
            <Link to={'/privacy-policy'} style={{ paddingRight: '1em' }}>Privacy Policy</Link><Link to={'/cookie-policy'}>Cookie Policy</Link>
          </CopyrightText>
        </Box>

        <Box className="row copyright" {...row}>
          <CopyrightText style={{ marginTop: '1em', fontSize: '0.7em' }}>
            Promoted & Printed (hosted) by Adam Clark on behalf of Godalming & Villages Residents, both at Godalming Residents, PO Box 877, Godalming, GU7 9LX.
          </CopyrightText>
        </Box>
        {/* End of copyright row */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '30%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '70%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '33.33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;
