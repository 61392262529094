import React, { useState } from 'react';
import Container from 'common/components/UI/Container';
import Input from 'common/components/Input';

import SectionWrapper, { SubscriptionForm, SubmitButton } from './ESU.style';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import axios from 'axios';

const schema = yup.object().shape({
  email: yup.string().email().required('Email address is required'),
});

const ESU = ({ data }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = async (data) => {
    setIsSubmitting(true);

    const portalId = '26902566';
    const formGuid = 'e3cfa563-7343-469d-953c-60a1a9a71e1b';
    const config = { // important!
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'email',
            value: data.email,
          },
        ],
      },
      config,
    );

    setIsSubmitted(true);
  };

  if (isSubmitted === true) {
    return (
      <SectionWrapper>
        <Container width="1260px">
          <h2>You are subscribed</h2>
          <p>Congratulations, we have added you to our news & updates email list</p>
          <p>You can unsubscribe at any time, either by following the unsubscribe link on the bottom of our emails or alternatively by emailing <strong>hello@godalmingresidents.co.uk</strong></p>
        </Container>
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper id="esu-signup">
      <Container width="1260px">
        <SubscriptionForm onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            className="input-field"
            inputType="text"
            name="email"
            style={{ padding: '20px' }}
            placeholder="Enter email address"
            register={register('email')}
            error={errors.email?.message}
          />
          <SubmitButton type="submit" disbled={isSubmitting}>
            { isSubmitting ? 'Submitting...' : 'Subscribe' }
          </SubmitButton>
        </SubscriptionForm>
        <h2>{ data.title }</h2>
        <p>{ data.description }</p>
      </Container>
    </SectionWrapper>
  )
}

export default ESU;
