import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.div`
  width: 100%;
  padding: 20px 0 40px;
  background-color: #345e3b;
  color: #fff
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  
  h2 {
    font-weight: 200;
    font-size: 2em;
    padding-top: 0.2em;
    margin-bottom: 0;
  }
  
  h2, p {
    color: #fff!important;
  }
`;

export const SubscriptionForm = styled.form`
    min-width: 500px;
    float: right;
    margin-top: 0.8em;
    
    @media only screen and (max-width: 667px) {
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 2em;
      
      .input-field {
        width: 100%!important;
      }
      
      button {
        display: inline-block;
        clear: both;
        width: 100%;
      }
    }
    
    .error {
      display: block;
      clear: both;
      color: #fff;
      padding-top: 0.3em;
      font-weight: bold;
    }
    
    .input-field {
      width: 70%;
      float: left;
      border: 0;
      text-shadow: none;
      border-radius: 0;
    }
`;

export const SubmitButton = styled.button`
    width: 30%;
    float: right;
    height: 60.5px;
    display: block;
    margin-top: -1px;
    background-color: #5d3456;
    color: #fff;  
    align-items: center;
    justify-content: center;
    border: 0;
    font-size: 16px;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0 10px 10px 0;
    
    &:disabled {
      background-color: #2c3e50;
      pointer-events: none;
    }
`;

export default SectionWrapper;
