import React from 'react';
import {CookieNotice} from "gatsby-cookie-notice";
import {Link} from "gatsby";

function Cookie() {
  return (
    <CookieNotice personalizeButtonEnable={false}>
      <h4>This websites uses cookies</h4>
      <p>We use cookies to improve our website. We will only use cookies if you allow us to do so by clicking by clicking on "Accept". Our cookie policy can <Link to="/cookie-policy">be found here</Link>.</p>
    </CookieNotice>
  );
}

export default Cookie;
