import React from 'react';
import { Link } from 'gatsby';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';

import logoImage from './logo.png';

const Navbar = ({ itemWidth }) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <NavbarWrapper className="navbar">
      <Container width={ itemWidth ? itemWidth : '1260px' }>
        <Link className="logo" to="/" style={{ float: 'right', display: 'block' }}>
          <Image src={logoImage} alt="Godalming & Villages Residents Logo" />
        </Link>
        <ul className="menu-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/residents-survey">Our Residents Survey</Link>
          </li>
        </ul>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
